<template>
  <base-section
    id="slogan"
    class="secondary py-13"
  >
    <v-container class="fill-height px-4 py-0">
        <v-responsive
          class="d-flex align-center mx-auto white--text"
          height="100%"
          max-width="1000"
          width="100%"
        >
          <base-subheading
            size="text-h4"
            weight="regular"
            title="Contact us for more information about our products and services"
            class="mx-auto text-uppercase text-center"
            mobileSize="text-h6"
          />
          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
            <base-btn
              @click=fnGoToContact
              color="primary"
              dark
              class="mx-auto"
            >
              Contact Us
            </base-btn>
          </div>
        </v-responsive>
      </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionSlogan',
    methods: {
      fnGoToContact () {
        this.$router.push('/contact')
      },
    },
  }
</script>
